import React, { Component } from 'react'

export class StartSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={14}
    fill="none"
   
  >
    <path
      fill="#F05127"
      d="M5.64 1.527c.582-1.877 3.238-1.877 3.82 0l.246.793a2 2 0 0 0 1.91 1.408h.986c1.915 0 2.736 2.432 1.211 3.591l-.954.726a2 2 0 0 0-.699 2.185l.327 1.053c.576 1.859-1.572 3.363-3.122 2.184l-.604-.46a2 2 0 0 0-2.422 0l-.605.46c-1.55 1.178-3.698-.325-3.121-2.184l.326-1.053a2 2 0 0 0-.699-2.185l-.954-.726C-.238 6.16.582 3.728 2.496 3.728h.987a2 2 0 0 0 1.91-1.408l.246-.793Z"
    />
  </svg>
    )
  }
}

export default StartSvg