import React, { Component } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './pages/MainPage'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Products from './pages/Products'
import SingleProduct from './components/SingleProduct'
import Contact from './pages/Contact'
import AboutUs from './pages/AboutUs'
import Project from './pages/Project'
import { connect } from 'react-redux'
import { getContent } from './actions/MainAction'
import Details from './pages/Details'
import SingleDetails from './pages/SingleDetails'
import ScrollToTop from './components/ScrollToTop'
import Loading from './components/Loading'

export class App extends Component {
  componentDidMount() {
    this.props.getContent(this.props.lang_id);
  }

  render() {
    const {loader} = this.props;
    return (
      <div>
        <BrowserRouter>
        <ScrollToTop />
          <Header />
          <Routes>
            <Route path='/' element={<MainPage />} />
            
            <Route path='/products' element={<Products />} />
            <Route path='/products/:id' element={<SingleProduct />} />

            <Route path='/details' element={<Details />} />
            <Route path='/details/:id' element={<SingleDetails />} />

            <Route path='/contact' element={<Contact />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/project' element={<Project />} />
          </Routes>
          <Footer />
          {loader ? <Loading /> : null}
        </BrowserRouter>
      </div>
    )
  }
}

const mapStateProps = (state) => ({
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
  loader: state.Data.loader,
})

const mapDispatchToProps = { getContent };

export default connect(mapStateProps, mapDispatchToProps)(App)