import React, { Component } from 'react'

export class YoutubeSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={17}
    fill="none"
 
  >
    <rect
      width={24.509}
      height={16.34}
      x={0.063}
      y={0.293}
      fill="#F05127"
      rx={3}
    />
    <path fill="#fff" d="m16.498 8.463-6.439 3.717V4.745l6.439 3.718Z" />
  </svg>
    )
  }
}

export default YoutubeSvg