import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import mateka from '../images/mateka.png';
import oztiryakiler from '../images/oztiryakiler.png';
import silverinox from '../images/silverinox.png';
import { getCooperationCompanies } from '../actions/MainAction';
import { connect } from 'react-redux';
import CooperationCompaniesCard from './cards/CooperationCompaniesCard';

export class Offers extends Component {
  componentDidMount() {
    this.props.getCooperationCompanies(this.props.lang_id);
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };


    const{cooperationCompaniesData}=this.props;
    // console.log(cooperationCompaniesData);

    return (
      <div className=' p-[20px_0px] shadow-md bg-[#fff]'>
        <Slider {...settings} className="slider">
          {
            cooperationCompaniesData?.map((data, i)=>(
              <CooperationCompaniesCard 
                key={i}
                image={data?.image_full_url}
              />
            ))
          }
        </Slider>
      </div>
    );
  }
}

const mapStateProps = (state) => ({
  lang_id: state.Data.lang_id,
  cooperationCompaniesData: state.Data.cooperationCompaniesData,
})

const mapDispatchToProps ={getCooperationCompanies}

export default connect (mapStateProps, mapDispatchToProps) (Offers);