import React, { Component } from 'react'

export class Card2 extends Component {
  render() {
    const {image, title, text } = this.props;
    return (
      <div className='rounded-[18px] bg-[#fff] flex items-center max-w-[410px] gap-[12px] p-[12px_24px]'>
        <div className='w-[88px]'>
            <img src={image}  />
        </div>
        <div className='flex flex-col gap-[8px]'>
            <h4 className='font-[500] text-[18px] leading-[22px] text-[#000]'>{title}</h4>
            <p className='text-[#8B8B8B] font-[300] text-[14px] leading-[16px]'>
              {text}
            </p>
        </div>
      </div>
    )
  }
}

export default Card2