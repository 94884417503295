import React, { Component } from 'react'
import PhoneSvg from '../svg/PhoneSvg'
import LocationSvg from '../svg/LocationSvg'
import { Link } from 'react-router-dom'

export class InfoNav extends Component {
  render() {
    return (
      <div className=' p-[20px_0] border-b-[0.5px] max-[1080px]:hidden max-[1080px]:p-[0px]'>
        <div className='container'>
          <div className='flex justify-between items-center'>
            <div>
              <span className='font-[400] text-[18px] leading-[22px] text-[var(--gray)]'>
                “Əliyev və K” Şəxsi istehsalat Kommersiya Şirkəti
              </span>
            </div>

            <div className='flex items-center gap-[64px]'>

              <Link to="tel:+994124413638" target='__blank' className='flex items-center gap-[12px]'>
                <PhoneSvg />
                <span className='font-[400] text-[18px] leading-[22px] text-[#030303]'>+994 12 441 36 38</span>
              </Link>

              <Link target="__blank" to="https://www.google.com/maps/place/88+Mir%C9%99li+Qa%C5%9Fqay,+Bak%C4%B1/@40.3915031,49.8438108,20z/data=!4m5!3m4!1s0x40307d71780e36d3:0xfc933bdcfc674bbb!8m2!3d40.3910338!4d49.84394?entry=ttu" className='flex items-center gap-[12px]'>
                <LocationSvg />
                <span className='font-[400] text-[18px] leading-[22px] text-[#030303]'>
                  Mirəli Qaşqay Küç.88A
                </span>
              </Link>

              {/* <div className='flex items-center gap-[10px]'>
                <span className='font-[500] text-[18px] leading-[22px] text-[#030303]'>AZ</span>
                <span className='font-[500] text-[18px] leading-[22px] text-[var(--gray)]'>RU</span>
                <span className='font-[500] text-[18px] leading-[22px] text-[var(--gray)]'>EN</span>
              </div> */}


            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default InfoNav