import React, { Component } from 'react'

export class Card9 extends Component {
  render() {
    const {embed_video, title} = this.props;
    return (
      <div className='flex flex-col gap-[10px] max-[500px]:gap-[10px] '>

        <h4 className='font-[450px] text-[20px] text-center text-[#000] max-[500px]:text-[16px]'>{title}</h4>
        <div className='embed_header_video rounded-[12px] w-full h-[320px] max-[500px]:max-h-[180px] ' dangerouslySetInnerHTML={{__html:embed_video}}></div>

      </div>
    )
  }
}

export default Card9