import React, { Component } from 'react';
import LocationSvg2 from '../svg/LocationSvg2';
import { Link } from 'react-router-dom';

export class Card6 extends Component {
  formatnumber(phoneNumber="") {
    // Telefon numarasının null veya undefined olup olmadığını kontrol edin
    if (!phoneNumber) {
      return ''; // Eğer telefon numarası null ise, boş bir string döndür
    }
    // Boşlukları kaldır ve formatlanmış telefon numarasını döndür
    return phoneNumber.replace(/\s+/g, '');
  }

  render() {
    const { title, address, time, number, number2, mail, working_day } = this.props;
    const formattedNumber = this.formatnumber(number);
    const formattedNumber2 = this.formatnumber(number2);

    return (
      <div className='flex justify-between items-center p-[18px_26px] border-[#7a7777] border-[1px] rounded-[12px] max-[450px]:flex-col max-[450px]:gap-[20px]'>

        <div className='flex items-center justify-between w-[56%] max-[900px]:w-[60%] max-[780px]:flex-col max-[780px]:items-start max-[780px]:gap-[20px] max-[500px]:w-1/2 max-[450px]:items-center'>
          <div className='flex flex-col gap-[8px]'>
            <h3 className='font-[500] text-[22px] text-[#F05127] leading-[33px] text-center'>{title}</h3> 
            <div className='flex items-center gap-[8px] max-[450px]:gap-[4px] w-[200px] max-[450px]:justify-center'>
              <div className='max-[450px]:mb-[4px]'>
                <LocationSvg2 />
              </div>
                <p className='font-[500] text-[12px]  text-[#3F3E3E] max-[450px]:text-center'>{address}</p>
            </div>
          </div>

          <div className='flex items-center gap-[6px] max-[700px]:flex-col'>
            <span className='font-[500] text-[14px] leading-[20px] text-[#F05127]'>Həftə içi </span>
            <span className='font-[500] text-[14px] leading-[20px] text-[#F05127]'>{working_day}</span>
          </div>
        </div>

        <div className='flex flex-col items-end gap-[8px] max-[450px]:items-center'>
          {number ? (
            <Link to={`tel:${formattedNumber2}`} target='__blank' className='font-[500] text-[14px] leading-[20px] text-[#F05127]'>{number}</Link>
          ) : null}

          {number2 ? (
            <Link to={`tel:${formattedNumber}`} target='__blank' className='font-[500] text-[14px] leading-[20px] text-[#F05127]'>{number2}</Link>
          ) : null}

          <span className='font-[500] text-[14px] leading-[20px] text-[#F05127]'>{mail}</span>
        </div>

      </div>
    );
  }
}

export default Card6;