import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import Card6 from '../components/cards/Card6'
import { getBranch } from '../actions/MainAction'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'


export class Contact extends Component {

  componentDidMount() {
    this.props.getBranch(this.props.lang_id);
  }

  render() {
    const {branchData, contentData} = this.props;
    // console.log(branchData);
    return (
      <main className='container'>
        <Helmet>
          <title>Filiallar | AİK - aik.az</title>

          <meta name="description" content="AİK şirkətinin filialları. Soyuducu satışı və xidmət məntəqələri. Yaxınlıqdakı AİK filialını tapın və məhsullarımızla tanış olun." />

          <meta name="keywords" content={`AIK filiallari, AİK şirkətinin filialları, Mərkəzi Sərgi Salonu, Təbriz Sərgi Salonu, Sədərək Sərgi Salonu, Buzovna Sərgi Salonu, Sumqayıt Sərgi Salonu, EuroCold Ehtiyyat Hissələri`} />

          <meta property="og:title" content="Filiallar | AİK - aik.az" />
          <meta property="og:description" content="AİK şirkətinin filialları. Soyuducu satışı və xidmət məntəqələri. Yaxınlıqdakı AİK filialını tapın və məhsullarımızla tanış olun."/>
          <meta property="og:image" content={"http://localhost:3001/static/media/bina.fda9fdbdf80243cf5273.png"} />
          <meta property="og:url" content={`https://aik.az/contact`} />

          <meta name="twitter:title" content="Filiallar | AİK - aik.az" />
          <meta name="twitter:description" content="AİK şirkətinin filialları. Soyuducu satışı və xidmət məntəqələri. Yaxınlıqdakı AİK filialını tapın və məhsullarımızla tanış olun."/>
          <meta name="twitter:image" content={"http://localhost:3001/static/media/bina.fda9fdbdf80243cf5273.png"} />

       </Helmet>
        <SectionHeader title={contentData?.sh_branch} />
        <section className=' flex flex-col gap-[32px] '>

          {
            branchData?.map((data, i)=>(
              <Card6
                key={i}
                id={data?.id}
                title={data?.translate?.title}
                address={data?.translate?.address}
                number={data?.phone_number}
                number2={data?.phone_number_2}
                mail={data?.email}
                working_day={data?.translate?.working_day}
              />
            ))
          }

        </section>
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  branchData: state.Data.branchData,
  contentData: state.Data.contentData
})
const mapDispatchToProps = { getBranch };
export default connect(mapStateToProps, mapDispatchToProps)( Contact)