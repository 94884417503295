import React, { Component } from 'react'

export class HamburgerSvg extends Component {
  render() {
    const{fill} = this.props;
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={27}
      fill="none"
   
    >
      <path
        fill="#F05127"
        d="M33.615 13.506a1.391 1.391 0 0 1-1.39 1.391H1.614a1.391 1.391 0 0 1 0-2.783h30.61a1.391 1.391 0 0 1 1.39 1.392Zm-32-9.74h30.61a1.391 1.391 0 1 0 0-2.782H1.614a1.391 1.391 0 1 0 0 2.783Zm30.61 19.479H1.614a1.391 1.391 0 0 0 0 2.782h30.61a1.391 1.391 0 0 0 0-2.782Z"
      />
    </svg>
    )
  }
}

export default HamburgerSvg