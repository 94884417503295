import React, { Component } from 'react'

export class RightArrowSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={18}
      fill="none"
     
    >
      <path
        fill="#272727"
        d="m11.5 9.297-8.919 8.64L.5 15.921l6.838-6.624L.5 2.673 2.581.657l8.919 8.64Z"
      />
    </svg>
    )
  }
}

export default RightArrowSvg