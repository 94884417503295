import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class Card7 extends Component {

  getStr(text) {
    if (text) {
      return text.replace(/<[^>]+>/g, ' ');
    }
    return '';
  }

  splitText(text) {
    if (text) {
      if (text.length > 60) {
        return text.substring(0, 60) + "...";
      } else {
        return text;
      }
    }
    return '';
  }

  getsetrtitle(title) {
    if (title) {
      return title.replace(/<[^>]+>/g, ' ');
    }
    return '';
  }

  splitTitle(title) {
    if (title) {
      if (title.length > 20) {
        return title.substring(0, 20) + "...";
      } else {
        return title;
      }
    }
    return '';
  }


  render() {
    const { image_full_url, title, description, id, price, className, imgHeight } = this.props;
    return (
      <>
        {image_full_url && description ? (
          <Link to={`/products/${id}`}>
            <div className={` ${className} flex flex-col bg-[#fff] rounded-[12px] shadow-lg p-[10px_6px]  gap-[8px] `} >
              <div className=' rounded-[12px] p-[6px] '>
                <img src={image_full_url} className={` ${imgHeight}  w-full h-auto rounded-[12px]`} />
              </div>

              <div className='flex flex-col gap-[8px]  ml-[12px]' >
                <div className='h-[30px]  max-[375px]:h-[30px]'>
                  <h4 className='font-[500] text-[20px] text-start text-[#000] max-[1250px]:text-[17px] max-[777px]:text-[16px] max-[468px]:text-[15px] max-[370px]:text-[18px] ' title={title}>
                    {this.splitTitle(this.getsetrtitle(title))}
                  </h4>
                </div>

                {price ? (
                  <div className='flex items-center gap-[8px] mt-[10px]'>
                    <span className='font-[400] text-[18px] leading-[22px] text-[#000] max-[1250px]:text-[16px] max-[425px]:text-[16px]'>
                      {price} Azn
                    </span>
                  </div>
                ) : null
                }

                {description ? (
                  <div className=' text-[#000] font-[300] h-[65px] max-[870px]:h-[100px] max-[790px]:h-[100px] max-[650px]:h-[100px] max-[425px]:h-[100px] max-[370px]:h-[60px] max-[375px]:h-[100px] text-start text-[14px] max-w-[230px] ' dangerouslySetInnerHTML={{ __html: this.splitText(this.getStr(description)) }}></div>
                ) : null
                }
              </div>

            </div>
          </Link>) : null
        }
      </>


    )
  }
}


export default (Card7)