import React, { Component } from 'react';
import logo from '../../images/logo.png';
import { Link } from 'react-router-dom';
import { postEmail } from '../../actions/MainAction';
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';

export class Footer extends Component {
    state = {
        inputValue: "",
        email: "",
        successMessage: "",
        btnDisable: false,
        error: " "
    }

    handleChange = (event) => {
        this.setState({ email: event.target.value, error: '' });
    };

    handleSubmit = (event) => {
        event.preventDefault(); // Sayfanın yenilenmesini önler

        const { email } = this.state;
        const { postEmail } = this.props;

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!email.trim()) {
            this.setState({ error: 'Emailinizi qeyd edin' });
            return;
        }

        if (!emailRegex.test(email)) {
            this.setState({ error: 'Email adresinizi düzgün yazdığınızdan əmin olun' });
            return;
        }

        const data = { email };
        // console.log(data);

        this.setState({ btnDisable: true });

        postEmail(data)
            .then((resp) => {
                if (resp === "success") {
                    this.setState({ successMessage: 'Göndərildi', error: '', email: '' });
                    this.setState({ btnDisable: false });
                    // console.log('success')
                }
            })
            .catch((error) => {
                // console.error('Error occurred:', error.message, error);
                this.setState({ successMessage: '', error: 'Email adresinizi düzgün yazdığınızdan əmin olun' });
                this.setState({ btnDisable: false }); // Hata durumunda düğmeyi tekrar etkinleştir
            });
    }

    render() {
        const { email, successMessage, btnDisable, error } = this.state;
        const { contentData } = this.props;
        // console.log(error);
        return (
            <footer className='bg-[#1B1B29] mt-[52px]'>
                <div className='container flex items-start justify-between p-[52px_0] max-[900px]:flex-col max-[900px]:items-center max-[900px]:gap-[38px] '>
                    <div className='flex items-start justify-between w-[50%] gap-[72px] max-[450px]:flex-col max-[450px]:gap-[40px] '>
                        <div className='flex flex-col items-start  gap-[32px] max-[450px]:items-center'>
                            <img src={logo} className='w-[95px] h-[40px]' />

                            <div className='flex flex-col item gap-[8px]'>
                                <span className='font-[500] text-[18px] leading-[24px] text-[#ffffff65] text-start max-[450px]:text-center'>Ünvan :</span>
                                <Link to="https://www.google.com/maps/place/88+Mir%C9%99li+Qa%C5%9Fqay,+Bak%C4%B1/@40.3915031,49.8438108,20z/data=!4m5!3m4!1s0x40307d71780e36d3:0xfc933bdcfc674bbb!8m2!3d40.3910338!4d49.84394?entry=ttu" 
                                  target='__blank'  className='font-[700] text-[20px] leading-[30px] text-[#fff] text-start max-[450px]:text-center'>
                                    Mirəli Qaşqay Küç.88A
                                </Link>
                            </div>

                            <div className='flex flex-col w-full gap-[4px]'>
                                <span className='ml-[4px] font-[500] text-[18px] leading-[24px] text-[#ffffff65] text-start max-[450px]:text-center'>Əlaqə :</span>
                                <Link to="tel:+994124413638" target='__blank' className='font-[700] text-[20px] leading-[30px] text-[#fff] text-start  max-[450px]:text-center'>+994 12 441 36 38</Link>
                                <Link to="tel:+994552230138" target='__blank' className='font-[700] text-[20px] leading-[30px] text-[#fff] text-start  max-[450px]:text-center'>+994 55 223 01 38</Link>
                            </div>
                        </div>

                        <div>
                            <ul className='flex flex-col gap-[23px]  text-start max-[450px]:text-center'>
                                <li className='font-[700] text-[22px]  text-[#fff]'>
                                    <Link to="/">
                                        Ana Səhifə
                                    </Link>
                                </li>
                                <li className='font-[700] text-[22px]  text-[#fff]'>
                                    <Link to="/products">
                                        Məhsullar
                                    </Link>
                                </li>
                                <li className='font-[700] text-[22px]  text-[#fff]'>
                                    <Link to="/details">
                                        Ehtiyyat Hissəıəri
                                    </Link>
                                </li>
                                <li className='font-[700] text-[22px]  text-[#fff]'>
                                    <Link to="/contact">
                                        Mağazalar
                                    </Link>
                                </li>
                                <li className='font-[700] text-[22px]  text-[#fff]'>
                                    <Link to="/about-us">
                                        Haqqımızda
                                    </Link>
                                </li>
                                <li className='font-[700] text-[22px]  text-[#fff]'>
                                    <Link to={'/project'} >
                                        İşlərimiz
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='bg-[#fff] flex flex-col justify-between h-[200px] rounded-[20px] p-[28px] max-[385px]:p-[14px] max-[385px]:h-[150px] max-[350px]:w-250px max-[330px]:w-[290px] max-[330px]:p-[8px] max-[330px]:h-[130px]'>
                        <span className='font-[500] text-[22px]  text-[#000] max-w-[157px] max-[385px]:text-[16px]'>Yeniliklərdən xəbərdar ol :</span>

                        <form className='flex items-center rounded-[50px] h-[50px] w-full bg-[#CCE0FA] max-[320px]:h-[30px]' onSubmit={this.handleSubmit}>
                            <input
                                className='bg-[#CCE0FA] rounded-[50px] pl-[12px] h-[40px'
                                placeholder='Mail Ünvanı'
                                type='email'
                                id='email'
                                value={email}
                                onChange={this.handleChange}
                            />
                            <button type='submit' className='bg-[#0713F3] rounded-[50px] w-[100px] h-[100%] flex items-center justify-center max-[350px]:w-[70px]'>
                                <span className='font-[300] text-[18px]  text-[#fff] max-[385px]:text-[14px] max-[350px]:text-[12px]'>Göndər</span>
                            </button>
                        </form>

                        <div className='mt-[4px] ml-[10px]'>
                            {successMessage && <span className=' text-[#0fff50] text-[14px] font-[500]'>{successMessage}</span>}
                            {error && <span className='text-[red] text-[14px] font-[500]'  >{error}</span>}
                        </div>
                    </div>
                </div>

                <div className='p-[16px_0px] flex justify-end container border-t-[1px] border-[#E0D5D5] max-[450px]:justify-center'>
                    <span className='font-[300] text-[18px] leading-[54px] text-[#E0D5D5] max-[350px]:text-[14px]'>
                        2024 © <span className='font-[700] text-[18px] leading-[54px] text-[#E0D5D5] max-[350px]:text-[14px]'>VOLTA DESİGN</span> STUDIO
                    </span>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = (state) => ({
    contentData: state.Data.contentData
})

const mapDispatchToProps = { postEmail, changeStateValue }
export default connect(mapStateToProps, mapDispatchToProps)(Footer);