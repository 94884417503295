import React, { Component } from 'react'

export class Card11 extends Component {
  render() {
    const {image, title, text, text1} = this.props;
    return (
      <div className='flex flex-col bg-[#fff] items-start gap-[12px] p-[16px] rounded-[30px]'>
        <img src={image}  className='w-[47px] h-[47px]'/>

        <div>
            <h3 className='font-[500] text-[#000] text-[22px] max-[500px]:text-[18px]'>{title}</h3>
        </div>

        <div>
            <span className='font-[400] text-[18px] text-[#000] max-[500px]:text-[17px]'>{text}</span>
        </div>

        {text1? (
            <div>
                <span className='font-[400] text-[18px] text-[#000] max-[500px]:text-[17px]'>{text1}</span>
            </div>
        ):null
        }
        
      </div>
    )
  }
}

export default Card11