import React, { Component } from 'react'

export class AboutUsCard extends Component {
  render() {
    const {text, image_full_url} = this.props;
    return (
        <div>
            <div className='font-[400] text-[18px] text-[#000]' dangerouslySetInnerHTML={{__html: text}}>
                
            </div>
            { image_full_url? (
              <img src={image_full_url} className='w-[auto] h-[500px]' />
            ):null
            }
        </div>
    )
  }
}

export default AboutUsCard