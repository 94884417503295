import React, { Component } from 'react'
import Card9 from '../components/cards/Card9'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux'
import { getourwork } from '../actions/MainAction';
import { Helmet } from 'react-helmet';

export class Project extends Component {

  componentDidMount() {
    this.props.getourwork(this.props.lang_id);
  }
  render() {
    const {contentData, ourWorkData} = this.props;
    // console.log(ourWorkData);
    return (
      <main className='container'>
         <Helmet>
          <title>İşlərimiz | AİK - aik.az</title>

          <meta name="description" content="AİK şirkətinin uğurlu layihələri və müştəri xidmətləri ilə tanış olun. Soyuducu satışı və xidmət sahəsində gördüyümüz işlər. aik.az saytında bizim işlərimizlə tanış olun." />

          <meta name="keywords" content={"AİK işlərimiz, layihələrimiz, uğurlu işlər, AİK xidmətləri, soyuducu satışı, AİK uğurları, AİK layihələri, AİK müştəri xidmətləri"} />

          <meta property="og:title" content="İşlərimiz | AİK - aik.az" />
          <meta property="og:description" content="AİK şirkətinin uğurlu layihələri və müştəri xidmətləri ilə tanış olun. Soyuducu satışı və xidmət sahəsində gördüyümüz işlər. aik.az saytında bizim işlərimizlə tanış olun."/>
          <meta property="og:image" content={"https://aik.az/static/media/bina.fda9fdbdf80243cf5273.png"} />
          <meta property="og:url" content={`https://aik.az/project`} />

          <meta name="twitter:title" content="İşlərimiz | AİK - aik.az" />
          <meta name="twitter:description" content="AİK şirkətinin uğurlu layihələri və müştəri xidmətləri ilə tanış olun. Soyuducu satışı və xidmət sahəsində gördüyümüz işlər. aik.az saytında bizim işlərimizlə tanış olun."/>
          <meta name="twitter:image" content={"https://aik.az/static/media/bina.fda9fdbdf80243cf5273.png"} />

       </Helmet>
        <SectionHeader title={contentData?.sh_our_work} />
        <section className='grid grid-cols-2 gap-[48px] max-[850px]:grid-cols-1'>

          {
            ourWorkData?.map((data, i)=>(
              <Card9 
                key={i}
                title={data?.translate?.title}
                embed_video={data?.video}
              />
            ))
          }
          
           
            
        </section>
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
  ourWorkData: state.Data.ourWorkData,
})
const mapDispatchToProps ={getourwork}
export default connect(mapStateToProps, mapDispatchToProps ) (Project)