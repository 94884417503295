import React, { Component } from 'react'

export class CooperationCompaniesCard extends Component {
  render() {
    const {image} = this.props;
    return (
        <div className='block '>
          <img src={image} className='w-[321px] h-[54px] p-[0px_15px]' />
        </div>
    )
  }
}

export default CooperationCompaniesCard