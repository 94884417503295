import React, { Component } from 'react'
import ProductList from './ProductList'
import { getDetailsCategories } from '../actions/MainAction';
import { connect } from 'react-redux';
import CloseBtn from './svg/CloseBtn';

export class DetailsListSection extends Component {

  componentDidMount() {
    this.props.getDetailsCategories(this.props.lang_id)
  }
  render() {
    const { detailsListData, closeMenu } = this.props;
    // console.log(detailsListData);

    return (
      <div className='max-[1024px]:bg-[var(--body)] bg-[#fff] rounded-[8px] max-[1024px]:rounded-[0px]'>
        <div className='hidden p-[24px_40px] max-[1024px]:flex max-[1024px]:justify-between '>

          <div className=''>
            <h2 className='font-[500] text-[18px] text-[#272727]'>Kataloq</h2>
          </div>
          <button onClick={closeMenu} className='mr-[20px]'><CloseBtn /></button>

        </div>

        <div className='  flex flex-col  gap-[20px] m-[0_15px_0_0] rounded-[8px] w-[320px] max-[1024px]:w-full p-[24px_40px] min-h-[100vh]'>
          {
            detailsListData?.map((data, i) => (
              <ProductList
                key={i}
                cotogories={data?.translate?.title}
                sub_cotgories={data?.sub_categories}
                id={data?.id}
                type="detail"
              />
            ))
          }

        </div>
      </div>

    )
  }
}

const mapStateProps = (state) => ({
  lang_id: state.Data.lang_id,
  detailsListData: state.Data.detailsListData,
})

const mapDispatchToProps = { getDetailsCategories }

export default connect(mapStateProps, mapDispatchToProps)(DetailsListSection)