import React, { Component } from 'react'
import StartSvg from '../components/svg/StartSvg'
import ManatSvg from '../components/svg/ManatSvg'
import { Link } from 'react-router-dom'
import FacebookSvg from '../components/svg/FacebookSvg'
import InstagramSvg from '../components/svg/InstagramSvg'
import YoutubeSvg from '../components/svg/YoutubeSvg'
import GoodSold from '../components/GoodSold'
import { connect } from 'react-redux'
import { withHooks } from '../actions/withHooks'
import { getSingleDetails } from '../actions/MainAction'
import { Helmet } from 'react-helmet'

export class SingleDetails extends Component {
  componentDidMount() {
    const { params } = this.props;
    this.props.getSingleDetails(params.id, this.props.lang_id);
  }
  getTextFromHTML = (text) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(text, 'text/html');
    return doc.children[0].children[1].textContent;
  }
  render() {
    const {detailSingleData} = this.props;
    // console.log(detailSingleData)

    const mainImage = detailSingleData?.images?.[0]?.image_full_url || " ";

    const titles = detailSingleData?.spesifications?.map(item => item?.translate?.title) || " "


  return (
    <main className='container'>
      {detailSingleData !=""?
        <Helmet>
          <title>{detailSingleData?.translate?.title} | AİK - aik.az</title>

          <meta name="description" content={this.getTextFromHTML(detailSingleData?.translate?.description)} />

          <meta name="keywords" content={`${detailSingleData?.translate?.title}, Ən yeni alman texnologiyası, Çatdırılma 3 - 15 gün, 1 il Rəsmi Zəmanət`} />

          <meta property="og:title" content={`${detailSingleData?.translate?.title} | AİK - aik.az`} />
          <meta property="og:description" content={this.getTextFromHTML(detailSingleData?.translate?.description)}/>
          <meta property="og:image" content={detailSingleData?.images[0]?.image_full_url} />
          <meta property="og:url" content={`https://aik.az/products/${detailSingleData?.id}`} />

          <meta name="twitter:title" content={`${detailSingleData?.translate?.title} | AİK - aik.az`} />
          <meta name="twitter:description" content={this.getTextFromHTML(detailSingleData?.translate?.description)}/>
          <meta name="twitter:image" content={detailSingleData?.images[0]?.image_full_url} />

        </Helmet>:null
      }

      <section>
        <div className='flex items-start gap-[32px] mt-[32px] max-[1000px]:flex-col max-[1000px]:items-center'>
          <div className='w-1/2 h-auto rounded-[12px] flex items-center max-[1000px]:w-full'>
            <div className='flex w-full flex-col'>

              <img src={mainImage}  className='w-full max-h-[500px] max-[500px]:max-h-[350px] max-[380px]:max-h-[300px] rounded-[8px]'/>

            </div>
          </div>

          <div className='flex flex-col  mt-[32px] w-1/2 gap-[12px] max-[1000px]:w-full'>
            <div className='flex flex-col gap-[6px]'>
              <h3 className='font-[500] text-[40px] leading-[55px] text-[#000]'>{detailSingleData?.translate?.title}</h3>

              <div className='flex items-start ml-[4px] gap-[4px]'>
                <StartSvg />
                <StartSvg />
                <StartSvg />
                <StartSvg />
                <StartSvg />
              </div>
            </div>

            <div className='font-[400] text-[18px] text-[#5E5E5E] leading-[27px] max-w-[530px]' dangerouslySetInnerHTML={{__html: detailSingleData?.translate?.description}}>
              
            </div>

            { detailSingleData?.price ? 
              ( <div className='border-t-[1px]  pt-[12px] gap-[10px] flex items-start w-full'>
                  <div className='flex items-center gap-[6px] border-l pl-[14px]'>
                    <div>
                      <ManatSvg width="28" height="25" />
                    </div>
                    <span className='text-[35px] font-[500]'>{detailSingleData?.price}</span>
                  </div>
                  
                
                  { detailSingleData?.raiting == 1 ?(
                    <div className='flex items-start'>
                      <div className=''>
                        <span className='text-[#F05127] font-[400] text-[16px] border-l pl-[7px]'>1metr'ə görə qiymət</span>
                      </div>
                      <div></div>
                    </div>) : null
                  }

                  { detailSingleData?.raiting == 2 ?(
                    <div className='flex items-start'>
                      <div className=''>
                        <span className='text-[#F05127] font-[400] text-[16px] border-l pl-[7px]'>1kub'a görə qiymət</span>
                      </div>
                      <div></div>
                    </div>) : null
                  }

                  { detailSingleData?.raiting == 3 ?(
                    <div className='flex items-start'>
                      <div className=''>
                        <span className='text-[#F05127] font-[400] text-[16px] border-l pl-[7px]'>1kv'a görə qiymət</span>
                      </div>
                      <div></div>
                    </div>) : null
                  }
                </div> 
              ) : null
                
            }
            
            <div className='border-t-[1px] border-b-[1px] flex items-center justify-between p-[24px_0px]'>
              <div className='border-l-[1px] pl-[14px]'>
                <span className='font-[400] text-[15px] text-[#363636]'>Ən yeni alman texnologiyası</span>
              </div>
              <div className='border-l-[1px] pl-[14px]'>
                <span className='font-[400] text-[15px] text-[#363636]'>Çatdırılma 3 - 15 gün</span>
              </div>
              <div className='border-l-[1px] pl-[14px]'>
                <span className='font-[400] text-[15px] text-[#363636]'>1 il Rəsmi Zəmanət</span>
              </div>
            </div>

            <div className='flex flex-col gap-[10px]'>
              <h5 className='font-[450] text-[26px] text-[#363636] leading-[30px]'>
                Bizi izlə !
              </h5>
              <span className='font-[400] text-[16px] leading-[19px] text-[#363636]'>
                Sosial medialarda bizi izləyin, yeniliklərdən ilk siz xəbərdar olun.
              </span>
              <div className='flex items-center gap-[20px]'>
                
                {/* <Link> <FacebookSvg /> </Link> */}
                <Link to="https://www.instagram.com/aikcompany/" target='__blank' > <InstagramSvg /> </Link>
                <Link to="https://youtube.com/@aikandcompany?si=DrlPa1KHj-h5u-lj" target='__blank'> <YoutubeSvg /> </Link>
                
              </div>
            </div>
          </div>
        </div>
      </section>


      {titles[0]? (
        <section className='flex flex-col gap-[20px] mt-[32px]'>
          <div>
            <h4 className='font-[450px] text-[22px] text-[#363636]'>Texniki Xüsusiyyətləri :</h4>
          </div>

          <div className='flex items-start gap-[42px]'>

            <div className='flex flex-col items-start gap-[8px]'>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[0]}</span>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[2]}</span>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[4]}</span>
            </div>

            <div className='flex flex-col items-start gap-[8px]'>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[1]}</span>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[3]}</span>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[5]}</span>
            </div>

          </div>
        </section>
        ) : null

      }

      

      <div className='mt-[52px]'>
        <GoodSold />
      </div>
    </main>
    )
  }
}

const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  detailSingleData: state.Data.detailSingleData,

})

const mapDispatchToProps = {getSingleDetails}
export default connect(mapStateToProps, mapDispatchToProps) (withHooks (SingleDetails))