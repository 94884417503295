import React, { Component } from 'react'

export class ManatSvg extends Component {
  render() {
    const {width, height } = this.props
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"

      >
      <path
        fill="#363636"
        d="M14.93 3.07 14.872.305l-1.858.988-.054 1.787C5.713 3.816 0 12.164 0 22.358c0 .657.024 1.307.07 1.947h2.537a28.18 28.18 0 0 1-.04-1.418c0-9.248 4.537-16.837 10.325-17.65l-.517 17.368 2.943-1.483-.343-15.882c5.777.83 10.3 8.412 10.3 17.647 0 .476-.017.948-.04 1.418h2.69c.047-.64.07-1.29.07-1.947 0-10.246-5.772-18.628-13.07-19.29l.005.002Z"
      />
    </svg>
    )
  }
}

export default ManatSvg