import React, { Component } from 'react'

export class BottomArrowSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={11}
      fill="none"

    >
      <path
        fill="#000"
        d="M9.014 11 .374 2.081 2.39 0l6.624 6.838L15.638 0l2.016 2.081L9.014 11Z"
      />
    </svg>
    )
  }
}

export default BottomArrowSvg