// import React, { Component } from 'react'
// import logo from '../../images/logo.png'
// import SearchSvg from '../svg/SearchSvg'
// import { Link, NavLink } from 'react-router-dom'
// import HamburgerSvg from '../svg/HamburgerSvg'
// import { connect } from 'react-redux'


// export class Nav extends Component {

//     state = {
//         inpRef: React.createRef()
//     }

//     componentDidMount() {
//         window.addEventListener("scroll", this.handleScroll);
//         window.addEventListener("click", this.handleClickOutside);
//     }

//     componentWillUnmount() {
//         window.removeEventListener("scroll", this.handleScroll);
//         window.removeEventListener("click", this.handleClickOutside);
//     }

//     handleScroll = () => {
//         if (window.scrollY >= 50) {
//             const nav = document.querySelector('.navbar');
//             nav.style.position = 'fixed';
//             nav.style.top = '0px';
//         } else {
//             const nav = document.querySelector('.navbar');
//             nav.style.position = 'inherit';
//         }
//     }

//     handleClickOutside = (event) => {
//         if (this.state.inpRef.current && !this.state.inpRef.current.contains(event.target) && !document.querySelector('.lbl-resp').contains(event.target)) {
//             this.closeNav(this.state.inpRef);
//         }
//     }

//     closeNav = (inpRef) => {
//         if (inpRef?.current) {
//             inpRef.current.checked = false;
//         }
//     }

//   render() {
//     const { contentData } = this.props;
//     const { inpRef } = this.state;
   
//     return (
//         <nav className=' w-full p-[25px_0] bg-[#f7f8fa]    z-[990]  navbar '> 
//             <div className='flex container items-center justify-between gap-[32px] w-full'> 
//                 <div>
//                     <Link to="/">
//                         <img src={logo} className='w-[110px] h-[40px]' />
//                     </Link>
//                 </div>
                

//                 <div className='menu'>
//                     <input ref={inpRef} type='checkbox' id='onclick' />

//                     <label htmlFor='onclick' className='lbl-resp'>
//                         <HamburgerSvg fill='#f05127'  />
//                     </label>

//                     <ul className='flex navlist items-center gap-[50px] max-[1110px]:gap-[26px]'>
//                         <li >
//                             <NavLink  to="/" onClick={() => this.closeNav(inpRef)} className='font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]'>
//                                 {contentData?.nav_home} 
//                             </NavLink>
//                         </li>
//                         <li>
//                             <NavLink to="/products" onClick={() => this.closeNav(inpRef)} className='font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]'>
//                                 Məhsullar
//                             </NavLink>
                            
//                         </li>
//                         <li >
//                             <NavLink to='/details'  onClick={() => this.closeNav(inpRef)} className='font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]'>
//                                 Ehtiyyat Hissələri 
//                             </NavLink>
//                         </li>
//                         <li >
//                             <NavLink to='/contact' onClick={() => this.closeNav(inpRef)} className=' font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]'> 
//                                 Fliallar 
//                             </NavLink>
//                         </li>
//                         <li>
//                             <NavLink to='/about-us' onClick={() => this.closeNav(inpRef)} className='font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]'>
//                                 Haqqımızda 
//                             </NavLink>
//                         </li>
//                         <li>
//                             <NavLink to="/project" onClick={() => this.closeNav(inpRef)} className='font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]'>
//                                 İşlərimiz
//                             </NavLink>
//                         </li>
//                     </ul>
//                 </div>
                
//                 <div className='w-[300px] h-[1px] max-[1080px]:hidden'>

//                 </div>
//                 {/* <div className='flex items-center justify-between border-[1px] border-[#363743] p-[12px_18px] rounded-[50px] w-[300px] max-[1080px]:hidden'>
//                     <input className='bg-[#f7f8fa] ' type='text' placeholder='axtar...' />
//                     <SearchSvg />
//                 </div> */}

//             </div>

           


//         </nav>
//     )
//   }
// }

// const mapStateProps = (state) => ({
//     contentData: state.Data.contentData,
//     lang_id: state.Data.lang_id
// })

// const mapDispatchToProps = {}
// export default connect (mapStateProps, mapDispatchToProps)( Nav)


import React, { Component } from 'react';
import logo from '../../images/logo.png';
import SearchSvg from '../svg/SearchSvg';
import { Link, NavLink } from 'react-router-dom';
import HamburgerSvg from '../svg/HamburgerSvg';
import { connect } from 'react-redux';

export class Nav extends Component {
    state = {
        inpRef: React.createRef()
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("click", this.handleClickOutside);
    }

    handleScroll = () => {
        const nav = document.querySelector('.navbar');
        if (window.scrollY >= 50) {
            nav.style.position = 'fixed';
            nav.style.top = '0px';
        } else {
            nav.style.position = 'inherit';
        }
    };

    handleClickOutside = (event) => {
        const { inpRef } = this.state;
        if (
            inpRef.current &&
            !inpRef.current.contains(event.target) &&
            !document.querySelector('.lbl-resp').contains(event.target)
        ) {
            this.closeNav(inpRef);
        }
    };

    closeNav = (inpRef) => {
        if (inpRef?.current) {
            inpRef.current.checked = false;
        }
    };

    render() {
        const { contentData } = this.props;
        const { inpRef } = this.state;

        return (
            <nav className="w-full p-[25px_0] bg-[#f7f8fa] z-[990] navbar max-[1024px]:border-b-[2px] max-[1024px]:p-[10px_0px]  max-[1024px]:mb-[30px]">
                <div className="flex container items-center justify-between gap-[32px] w-full">
                    <div>
                        <Link to="/">
                            <img src={logo} className="w-[110px] h-[40px]" alt="Logo" />
                        </Link>
                    </div>

                    <div className="menu">
                        <input ref={inpRef} type="checkbox" id="onclick" className="hidden" />

                        <label htmlFor="onclick" className="lbl-resp">
                            <HamburgerSvg fill="#f05127" />
                        </label>

                        <ul className="flex navlist items-center gap-[50px] max-[1110px]:gap-[26px]">
                            <li>
                                <NavLink
                                    to="/"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    {contentData?.nav_home}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/products"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    Məhsullar
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/details"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    Ehtiyyat Hissələri
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/contact"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    {contentData?.sh_branch}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/about-us"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    Haqqımızda
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/project"
                                    onClick={() => this.closeNav(inpRef)}
                                    className="font-[500] text-[16px] leading-[20px] text-[#7A7777] hover:text-[#030303] hover:border-b-[2px] hover:border-[#7A7777]"
                                >
                                    İşlərimiz
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="w-[300px] h-[1px] max-[1024px]:hidden "> </div>

                    {/* Gelecekte arama çubuğunu eklemek için bu bölümü etkinleştirebilirsiniz */}
                    {/* <div className="flex items-center justify-between border-[1px] border-[#363743] p-[12px_18px] rounded-[50px] w-[300px] max-[1080px]:hidden">
                        <input className="bg-[#f7f8fa]" type="text" placeholder="axtar..." />
                        <SearchSvg />
                    </div> */}
                </div>
            </nav>
        );
    }
}

const mapStateToProps = (state) => ({
    contentData: state.Data.contentData,
    lang_id: state.Data.lang_id
});

export default connect(mapStateToProps)(Nav);