import React, { Component } from 'react'

export class LocationSvg extends Component {
  render() {
   
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={23}
    fill="none"
   
  >
    <g fill="#7A7777" clipPath="url(#a)">
      <path d="M8.5 0C6.247.003 4.086.938 2.493 2.6.9 4.26.003 6.512 0 8.863c0 3.492 1.405 7.026 4.057 10.218a21.837 21.837 0 0 0 4.027 3.79c.124.085.268.13.416.129a.727.727 0 0 0 .416-.128 21.838 21.838 0 0 0 4.027-3.79C15.595 15.888 17 12.366 17 8.862c-.003-2.35-.9-4.602-2.493-6.263C12.914.938 10.753.002 8.5 0Zm0 21.23c-1.563-1.221-6.978-5.89-6.978-12.367a7.442 7.442 0 0 1 2.048-5.14c1.308-1.362 3.08-2.128 4.93-2.13 1.85.002 3.622.768 4.93 2.13a7.442 7.442 0 0 1 2.048 5.14c0 6.476-5.415 11.152-6.978 12.367Z" />
      <path d="M8.5 5.347c-.894 0-1.752.37-2.384 1.03a3.594 3.594 0 0 0-.988 2.486c0 .933.355 1.827.988 2.486.632.66 1.49 1.03 2.384 1.03.443 0 .881-.09 1.29-.268.41-.176.781-.435 1.094-.762a3.53 3.53 0 0 0 .731-1.14 3.646 3.646 0 0 0 0-2.691 3.529 3.529 0 0 0-.73-1.141 3.366 3.366 0 0 0-1.095-.762 3.252 3.252 0 0 0-1.29-.268Zm0 5.445a1.818 1.818 0 0 1-1.306-.567 1.977 1.977 0 0 1 0-2.724A1.818 1.818 0 0 1 8.5 6.934c.49.002.96.206 1.306.567a1.978 1.978 0 0 1 0 2.724 1.818 1.818 0 0 1-1.306.567Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h17v23H0z" />
      </clipPath>
    </defs>
  </svg>
    )
  }
}

export default LocationSvg