import React, { Component } from 'react'
import InfoNav from './InfoNav'
import Nav from './Nav'

export class Header extends Component {
  render() {
    return (
      <header>
        <InfoNav />
        <Nav />
      </header>
    )
  }
}

export default Header