import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export class SliderCard extends Component {
  getsetrtitle(title) {
    if (title) {
      return title.replace(/<[^>]+>/g, ' ');
    }
    return '';
  }

  splitTitle(title) {
    if (title) {
      if (title.length > 13) {
        return title.substring(0, 13) + "...";
      } else {
        return title;
      }
    }
    return '';
  }
  render() {
    const{image_full_url, title, description, id, price, className, imgHeight} = this.props;
 
    return (
        <Link to={`/products/${id}`} >
        <div className={` ${className} flex flex-col bg-[#fff] rounded-[12px] shadow-lg p-[10px_6px]  gap-[8px] `} >
          <div className=' rounded-[12px] p-[6px] '>
            <img src={image_full_url} className={` ${imgHeight}  w-full h-[auto] rounded-[12px]`} />
          </div>

          <div className='flex flex-col h-auto gap-[8px] ml-[12px] max-[768px]:items-center' >
            <div className='flex justify-start max-[768px]:h-[30px] max-[710px]:h-[40px]  max-[768px]:justify-center'>
              <h4 className='font-[500] text-[20px] max-[768px]:text-[17px] max-[768px]:h-[30px] max-[710px]:text-[16px] max-[710px]:h-[80px] text-[#000] text-center' title={title}>
                {this.splitTitle(this.getsetrtitle(title))}
              </h4>
            </div>
            
            <div className='flex justify-start max-[768px]:justify-center'>
              <span className='font-[300] text-[18px] leading-[22px] text-[#000] max-[768px]:text-center '>
                {price} AZN
              </span>
            </div>
            

            <div className='embed_video  text-[#7A7777] font-[300]  text-start text-[14px] leading-[20px] max-w-[230px]' dangerouslySetInnerHTML={{__html: description}}></div>
          </div>
          
        </div>
      </Link>
    )
  }
}

export default SliderCard