import React, { Component } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card12 from './Card12';

import card1_turn_on from '../../images/turn_on.png';
import card1_turn_off from '../../images/turn_off.png';
import card2_turn_on from '../../images/slider_turn_on.png'
import card2_turn_off from '../../images/silider_turn_off.png'

export class MainSliderCard extends Component {
    
  render() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1150,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 620,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

      
    return (
      <div>
        <Slider {...settings} className="slider">
          <div>
            <Card12 
              turn_on={card1_turn_on}
              turn_off={card1_turn_off}
            />
          </div>

          <div>
            <Card12 
              turn_on={card2_turn_on}
              turn_off={card2_turn_off}
            />
          </div>
        </Slider>
      </div>
    )
  }
}

export default MainSliderCard