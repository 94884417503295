export const initialState = {
    lang_id:1,
    loader: false,
    loaderLong: false,
    
    contentData:{},
    branchData:[],
    productListData:[],
    detailsListData: [],
    cooperationCompaniesData:[],
    ourWorkData:[],
    detailsData:[],
    aboutData:[],

    productsData: [],
    mostSaleProductData:[],
    offerStatusProductData:[],

    productSingleData: '',
    detailSingleData: '',
    productType: [],
   
}
