import React, { Component } from 'react'

export class PhoneSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
  >
    <path
      fill="#7A7777"
      d="M0 2.477A2.477 2.477 0 0 1 1.47.21c.312-.138.65-.21.993-.21h3.31A2.463 2.463 0 0 1 8.23 2.184c.083.733.233 1.457.45 2.162a2.477 2.477 0 0 1-.615 2.47L6.727 8.152a15.382 15.382 0 0 0 6.12 6.118l1.336-1.336a2.464 2.464 0 0 1 2.47-.608c.706.21 1.43.359 2.162.443A2.462 2.462 0 0 1 21 15.223v3.31a2.462 2.462 0 0 1-.803 1.817 2.448 2.448 0 0 1-1.9.638 20.123 20.123 0 0 1-6.817-1.869 20.353 20.353 0 0 1-5.624-3.978 20.268 20.268 0 0 1-3.979-5.623 20.114 20.114 0 0 1-1.87-6.816v-.225H0Zm7.148 2.597a.946.946 0 0 0 0-.247 13.164 13.164 0 0 1-.51-2.462.826.826 0 0 0-.827-.751h-3.31a.811.811 0 0 0-.781.563.848.848 0 0 0-.038.345 18.577 18.577 0 0 0 16.765 16.83.848.848 0 0 0 .646-.21.81.81 0 0 0 .263-.608v-3.31a.826.826 0 0 0-.751-.826 13.575 13.575 0 0 1-2.455-.503.826.826 0 0 0-.834.195l-2.184 2.184-.526-.262a17.064 17.064 0 0 1-7.65-7.65l-.263-.525 2.184-2.185a.84.84 0 0 0 .24-.578h.03Z"
    />
  </svg>
    )
  }
}

export default PhoneSvg