import React, { Component } from 'react'

export class CloseBtn extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"

  >
    <path
      fill="#272727"
      fillOpacity={0.75}
      fillRule="evenodd"
      d="M5.576 6.636.737 1.797 2.03.505 6.87 5.343 11.707.505 13 1.797 8.161 6.636l4.84 4.838-1.294 1.293L6.87 7.93 2.03 12.767.737 11.474l4.839-4.838Z"
      clipRule="evenodd"
    />
  </svg>
    )
  }
}

export default CloseBtn