import React, { Component } from 'react'

export class SectionHeader extends Component {
  render() {
    const {title, text} = this.props;
    return (
      <div className='flex justify-center  w-full gap-[8px]'>
        <h2 className='font-[700] text-[40px] text-center text-[#030303] max-[550px]:text-[30px] max-[400px]:text-[26px]'>
          {title}
        </h2>
      </div>
    )
  }
}

export default SectionHeader