import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card3 from './cards/Card3';
import { connect } from 'react-redux';
import { getOfferStatusProducts } from '../actions/MainAction';
import Card7 from './cards/Card7';
import SliderCard from './cards/SliderCard';


export class CardSlider extends Component {

  componentDidMount(){
    const {lang_id, getOfferStatusProducts, } = this.props;
    getOfferStatusProducts(lang_id, 0.4, "offer_status")
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 620,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };


    const {offerStatusProductData} = this.props;
    // console.log(productsData);
    
    return (
      <div className=' p-[20px_0px] max-[375px]:p-[0px]'>
        <Slider {...settings} className="slider">
          {
            offerStatusProductData?.map((data, i)=>(
              <SliderCard 
                key={i}
                image_full_url={data?.images[0]?.image_full_url}
                title={data?.translate?.title}
                price={data?.price}
                id={data?.id}
                className="m-[10px]"
                // imgHeight="h-[220px]"
           
              />
            ))
          }
          {/* Diğer logoları buraya ekleyin */}
        </Slider>
      </div>
    );
  }
}

const mapStateProps = (state) => ({
  lang_id: state.Data.lang_id,
  offerStatusProductData: state.Data.offerStatusProductData,
})

const mapDispatchToProps = {getOfferStatusProducts}
export default connect ( mapStateProps, mapDispatchToProps) (CardSlider);