import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class ViewAllBtn extends Component {
  render() {
    const {href} = this.props;
    return (
      <Link to={href}>
        <button className=' p-[7px_15px] rounded-[50px] font-[700] text-[16px] text-[#fff] bg-[#F05127]'>Hamısına bax</button>
      </Link>
    )
  }
}

export default ViewAllBtn