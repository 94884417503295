import React, { Component } from 'react'

export class LocationSvg2 extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={19}
        fill="none"
  
      >
        <path
          fill="#F05127"
          d="M7.464.234a6.871 6.871 0 0 0-4.868 2.037 6.98 6.98 0 0 0-2.02 4.906c0 2.735 1.138 5.504 3.287 8.005a17.47 17.47 0 0 0 3.264 2.97c.1.066.218.1.337.1.12 0 .237-.034.337-.1a17.467 17.467 0 0 0 3.264-2.97c2.15-2.5 3.288-5.26 3.288-8.005a6.98 6.98 0 0 0-2.02-4.906A6.872 6.872 0 0 0 7.464.234Zm0 16.631c-1.267-.956-5.655-4.614-5.655-9.688.002-1.51.6-2.958 1.66-4.025a5.638 5.638 0 0 1 3.995-1.67 5.638 5.638 0 0 1 3.996 1.67 5.727 5.727 0 0 1 1.66 4.025c0 5.074-4.389 8.737-5.656 9.688Z"
        />
        <path
          fill="#F05127"
          d="M7.464 4.424c-.725 0-1.42.29-1.932.807a2.765 2.765 0 0 0-.8 1.947c0 .73.287 1.431.8 1.948a2.722 2.722 0 0 0 2.978.597 2.731 2.731 0 0 0 1.48-1.49 2.773 2.773 0 0 0-.593-3.002 2.732 2.732 0 0 0-1.933-.807Zm0 4.265a1.498 1.498 0 0 1-1.058-.444 1.522 1.522 0 0 1-.441-1.067c.001-.4.16-.784.44-1.067.281-.283.662-.443 1.06-.444.397.001.777.16 1.058.444.28.283.44.667.44 1.067 0 .4-.16.784-.44 1.067-.28.283-.661.443-1.059.444Z"
        />
      </svg>
    )
  }
}

export default LocationSvg2