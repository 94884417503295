import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Card11 from './cards/Card11'

import bina from '../images/bina.png'
import bag from '../images/bag.png'
import location from '../images/location.png'
import phone from '../images/phone.png'
import services from '../images/services.png'


export class MainAboutUs extends Component {
  render() {
    return (
      <div className=' grid grid-cols-2 gap-[32px] max-[900px]:grid-cols-1'>

        <div className='p-[20px] flex flex-col bg-[#fff] rounded-[30px] max-[500px]:p-[14px]'>
            <img src={bina} className='w-full h-full rounded-[30px]'/>

            <div className='mt-[16px]'>
                <p className='font-[400] text-[22px] text-[#000] max-[500px]:text-[18px]'>
                    Ünvanımızı Google Map-dən və Waze tətbiqindən tapa bilərsiniz.
                </p>
            </div>
            
        </div>

        <div className='grid grid-cols-2 gap-[28px]  max-[500px]:grid-cols-1'>

            <Card11 
                image={bag}
                title="Peşəkarlıq"
                text="1993-cü ildən etibarən Restoran və Mətbəx Avadanlıqlarının istehsalı və satışı ilə xidmətinizdəyik."
            />

            <Card11 
                image={location}
                title="Ünvan"
                text="Mirəli Qaşqay küç. 88A"
            />

            <Card11 
                image={phone}
                title="Əlaqə"
                text="+994 12 441 36 38"
                text1="+994 55 223 01 38"
            />

            <Card11 
                image={services}
                title="Servis Xidməti"
                text="+994 55 223 01 23"
            />

        </div>

      </div>
    )
  }
}

export default MainAboutUs