import React, { Component } from 'react'
import RightArrowSvg from './svg/RightArrowSvg';
import BottomArrowSvg from './svg/BottomArrowSvg';
import { getDetails, getProducts } from '../actions/MainAction';
import { connect } from 'react-redux';

export class ProductList extends Component {
    state={
        visible:false
    }

    getByCategory=(id)=>{
        if(this.props.type== "detail"){
             this.props.getDetails(this.props.lang_id, 1,id)
        }else{
            this.props.getProducts(this.props.lang_id, 1,id)
        }
       
       
    }

    getBySub=(id)=>{
        if(this.props.type== "detail"){
            this.props.getDetails(this.props.lang_id, 1,"",id)
        }else{
            this.props.getProducts(this.props.lang_id, 1,"",id)
        }
        
        
    }
  render() {
    const {visible} = this.state;
    const {cotogories, sub_cotgories, id} = this.props;
    // console.log(sub_cotgories)
    return (
      <div>
        <div className='flex flex-col bg-[#fff] max-[1024px]:bg-[var(--body)]  w-full '>
            <div onClick={()=>this.setState({visible: !visible})} className='flex items-center gap-[10px] w-full justify-between cursor-pointer'>
                <p onClick={this.getByCategory.bind(this, id)} className='font-[500] text-[20px]   leading-[32px] text-[#272727]'>{cotogories}</p>
                <div  className='cursor-pointer mt-[6px] w-[20px]'>
                    {
                        !visible?
                        <RightArrowSvg />:<BottomArrowSvg />
                    }
                </div>
            </div>
            
            {
                visible?
                <div className='' > 
                    {/* <button className='font-[400] text-[18px] leading-[32px] text-[#272727] block'>{sub_cotgories}</button> */}
                    {sub_cotgories?.map((sub, index) => (
                                <button onClick={this.getBySub.bind(this, sub?.id)} key={index} className='font-[400] text-start text-[18px] border-b-[1px] leading-[32px] text-[#272727] block'>
                                    {sub?.translate?.title}
                                </button>
                            ))}
                </div>:null

            }
        </div>
      </div>
    )
  }
}
const mapStateProps = (state)=>({
    lang_id: state.Data.lang_id
});
const mapDispatchToProps = {getDetails, getProducts}
export default connect(mapStateProps, mapDispatchToProps)(ProductList)