import React, { Component } from 'react'

export class SectionDescription extends Component {
  render() {
    const{text} = this.props;
    return (
      <div className='mt-[10px]'>
        <p className='font-[400] text-center text-[18px] leading-[20px] text-[#676464]'>{text}</p>
      </div>
    )
  }
}

export default SectionDescription