import React, { Component } from 'react'
import Card10 from '../components/cards/Card10'
import SectionHeader from '../components/SectionHeader'
import CatalogSvg from '../components/svg/CatalogSvg'
import { getDetails } from '../actions/MainAction'
import { connect } from 'react-redux'
import DetailsListSection from '../components/DetailsListSection'
import LoadMoreBtn from '../components/LoadMoreBtn'
import { Helmet } from 'react-helmet'

export class Details extends Component {

    state = {
        menuVisible: false,
        screenWidth: window.innerWidth,
    };

    toggleMenu = () => {
        this.setState((prevState) => ({
          menuVisible: !prevState.menuVisible,
        }));
    };

    componentDidMount(){
        const {getDetails} = this.props;
        getDetails(this.props.lang_id, 1, "", " ")

    window.addEventListener('resize', this.updateScreenWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }

    updateScreenWidth = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    loadMore=(page)=>{
        const {lang_id} = this.props
        this.props.getDetails(lang_id, page)
      }

    
  render() {
    const { menuVisible, screenWidth } = this.state;
    const {detailsData} = this.props;
    // console.log(detailsData);
    
    return (

        <main>
            <Helmet>
                <title>Soyuducu Ehtiyyat Hissələri | AİK - aik.az</title>

                <meta name="description" content="AİK şirkətindən keyfiyyətli soyuducu ehtiyyat hissələri. aik.az saytında geniş seçim və uyğun qiymətlərlə ehtiyyat hissələri. Soyuducunuzun ehtiyyat hissələrini burada tapın." />

                <meta name="keywords" content="bar və kafe avadanlıqları,un məmulatları, sobalar,mexaniki mətbəx avadanlıqları, bulaşıqxana avadanlıqları, polimit löhvə, market avadanlıqları, soyuducu ehtiyyat hissələri, soyuducu ehtiyyat, soyuducu hissələri, ehtiyyat hissələri al, soyuducu üçün hissələr" />

                <meta property="og:title" content="Soyuducu Ehtiyyat Hissələri | AİK - aik.az" />
                <meta property="og:description" content="AİK şirkətindən keyfiyyətli soyuducu ehtiyyat hissələri. aik.az saytında geniş seçim və uyğun qiymətlərlə ehtiyyat hissələri. Soyuducunuzun ehtiyyat hissələrini burada tapın." />
                <meta property="og:image" content="https://aik.az/images/soyuducu_mator.PNG" />
                <meta property="og:url" content="https://aik.az/details" />

                <meta name="twitter:title" content="Soyuducu Ehtiyyat Hissələri | AİK - aik.az" />
                <meta name="twitter:description" content="AİK şirkətindən keyfiyyətli soyuducu ehtiyyat hissələri. aik.az saytında geniş seçim və uyğun qiymətlərlə ehtiyyat hissələri. Soyuducunuzun ehtiyyat hissələrini burada tapın." />
                <meta name="twitter:image" content="https://aik.az/images/soyuducu_mator.PNG" />

            </Helmet>

            <div className='container flex w-full'>
                <div className='rounded-[12px] hidden max-[1024px]:inline-block'>
                    <label id='kataloq' className='flex items-center gap-[6px]' onClick={this.toggleMenu}>
                        <CatalogSvg />
                        <span className='font-[500] text-[18px] text-[#00]'>Kataloq</span>
                    </label>
                </div>
            </div>


            <section className='flex items-start gap-[32px] container pt-[32px]'>
                {/* Show ProductListSection only when screen is larger than 1024px or when menuVisible is true */}
                {menuVisible && screenWidth <= 1024 && (
                    <div className={`product-list-wrapper listclass ${menuVisible ? 'visible' : ''} ${menuVisible && screenWidth <= 1024 ? 'full-screen' : ''}`}>
                        <DetailsListSection closeMenu={this.toggleMenu} />
                    </div>
                )}

                {screenWidth > 1024 && (
                    <div className='product-list-wrapper listclass '>
                        <DetailsListSection closeMenu={this.toggleMenu} />
                    </div>
                )}
            

                <div className='flex flex-col w-full'>
                   
                    <div className='mt-[-10px] mb-[20px]'>
                        <div className='flex justify-start w-full gap-[8px]'>
                            <h2 className='font-[500] text-[40px] text-center text-[#030303] max-[550px]:text-[30px] max-[400px]:text-[26px]'>
                                Ehtiyyat Hissələri
                            </h2>
                        </div>
                    </div>
                    


                    <div className='grid grid-cols-3 gap-[32px] max-[1024px]:gap-[20px] max-[1024px]:grid-cols-4 max-[768px]:grid-cols-3 max-[580px]:grid-cols-2 max-[370px]:grid-cols-1'>
                        
                        {
                            detailsData?.map((data, i)=>(
                                <Card10 
                                    key={i}
                                    image_full_url={data?.images[0]?.image_full_url}
                                    description={data?.translate?.description}
                                    title={data?.translate?.title}
                                    id={data?.id}
                                    price={data?.price}
                                />
                            ))
                        }
                        
                    </div>

                    {detailsData?.length >= 20? (
                        <LoadMoreBtn onLoadData={this.loadMore} defCount={20} count={detailsData?.length} />
                    ) : null} 
                </div>
           
                {/* <DetailsSec /> */}

            </section>
        </main>
        
    )
  }
}

const mapStateProps =(state) => ({
  lang_id: state.Data.lang_id,
  detailsData: state.Data.detailsData,
})

const mapDispatchToProps = {getDetails}
export default connect(mapStateProps, mapDispatchToProps) (Details)