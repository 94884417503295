import React, { Component } from 'react'
import img from '../images/cold1.png'
import StartSvg from './svg/StartSvg'
import FacebookSvg from './svg/FacebookSvg'
import InstagramSvg from './svg/InstagramSvg'
import YoutubeSvg from './svg/YoutubeSvg'
import GoodSold from './GoodSold'
import { connect } from 'react-redux'
import { withHooks } from '../actions/withHooks'
import { getSingleProducts, getSingleProductType } from '../actions/MainAction'
import ManatSvg from './svg/ManatSvg'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export class SingleProduct extends Component {
  

  componentDidMount() {
    const { params } = this.props;
    this.props.getSingleProducts(params.id, this.props.lang_id);
  }

  getTextFromHTML = (text) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(text, 'text/html');
    return doc.children[0].children[1].textContent;
  }

  render() {
    const { productType, productSingleData } = this.props;
    // console.log(productSingleData);

    const mainImage = productSingleData?.images?.[0]?.image_full_url || " ";

    const titles = productSingleData?.spesifications?.map(item => item?.translate?.title) || " "

    return (
      <main className='container'>

        {
          productSingleData !=""?
          <Helmet>
          <title>{productSingleData?.translate?.title} | AİK - aik.az</title>

          <meta name="description" content={this.getTextFromHTML(productSingleData?.translate?.description)} />

          <meta name="keywords" content={`${productSingleData?.translate?.title}, Ən yeni alman texnologiyası, Çatdırılma 3 - 15 gün, 1 il Rəsmi Zəmanət`} />

          <meta property="og:title" content={`${productSingleData?.translate?.title} | AİK - aik.az`} />
          <meta property="og:description" content={this.getTextFromHTML(productSingleData?.translate?.description)}/>
          <meta property="og:image" content={productSingleData?.images[0]?.image_full_url} />
          <meta property="og:url" content={`https://aik.az/products/${productSingleData?.id}`} />

          <meta name="twitter:title" content={`${productSingleData?.translate?.title} | AİK - aik.az`} />
          <meta name="twitter:description" content={this.getTextFromHTML(productSingleData?.translate?.description)}/>
          <meta name="twitter:image" content={productSingleData?.images[0]?.image_full_url} />

       </Helmet>:null
        }
       
        <section>
          <div className='flex items-start gap-[32px] mt-[32px] max-[1000px]:flex-col max-[1000px]:items-center'>
            <div className='w-1/2 h-auto rounded-[12px] flex items-center max-[1000px]:w-full'>
              <div className='flex w-full flex-col'>
                <img src={mainImage} className='w-full max-h-[500px] rounded-[8px]'/>
              </div>
            </div>

            <div className='flex flex-col   w-1/2 gap-[12px] max-[1000px]:w-full'>
              <div className='flex flex-col gap-[6px]'>
                <h3 className='font-[500] text-[40px] leading-[55px] text-[#000]'>{productSingleData?.translate?.title}</h3>

                <div className='flex items-start ml-[4px] gap-[4px]'>
                  <StartSvg />
                  <StartSvg />
                  <StartSvg />
                  <StartSvg />
                  <StartSvg />
                </div>
              </div>

              <div className='font-[400] text-[18px] text-[#5E5E5E] leading-[27px] max-w-[530px]' dangerouslySetInnerHTML={{__html: productSingleData?.translate?.description}}>
                
              </div>

              <div className='border-t-[1px]  pt-[12px] gap-[10px] flex items-start w-full'>
                <div className='flex items-center gap-[6px] border-l pl-[14px]'>
                  <div>
                    <ManatSvg width="28" height="25" />
                  </div>
                  <span className='text-[35px] font-[500]'>{productSingleData?.price}</span>
                </div>
              
                { productSingleData?.raiting == 1 ?(
                  <div className='flex items-start'>
                    <div className=''>
                      <span className='text-[#F05127] font-[400] text-[16px] border-l pl-[7px]'>1metr'ə görə qiymət</span>
                    </div>
                    <div></div>
                  </div>) : null
                }

                { productSingleData?.raiting == 2 ?(
                  <div className='flex items-start'>
                    <div className=''>
                      <span className='text-[#F05127] font-[400] text-[16px] border-l pl-[7px]'>1kub'a görə qiymət</span>
                    </div>
                    <div></div>
                  </div>) : null
                }

                { productSingleData?.raiting == 3 ?(
                  <div className='flex items-start'>
                    <div className=''>
                      <span className='text-[#F05127] font-[400] text-[16px] border-l pl-[7px]'>1kv'a görə qiymət</span>
                    </div>
                    <div></div>
                  </div>) : null
                }
              
              </div>

              <div className='border-t-[1px] border-b-[1px] flex items-center justify-between p-[24px_0px] max-[500px]:flex-col max-[500px]:items-start max-[500px]:gap-[12px]'>
                <div className='border-l-[1px] pl-[12px]'>
                  <span className='font-[400] text-[15px] text-[#363636] max-[500px]:text-[14px]'>Ən yeni alman texnologiyası</span>
                </div>
                <div className='border-l-[1px] pl-[12px]'>
                  <span className='font-[400] text-[15px] text-[#363636] max-[500px]:text-[14px]'>Çatdırılma 3 - 15 gün</span>
                </div>
                <div className='border-l-[1px] pl-[12px]'>
                  <span className='font-[400] text-[15px] text-[#363636] max-[500px]:text-[14px]'>1 il Rəsmi Zəmanət</span>
                </div>
              </div>

              <div className='flex flex-col gap-[10px]'>
                <h5 className='font-[450] text-[26px] text-[#363636] leading-[30px]'>
                  Bizi izlə !
                </h5>
                <span className='font-[400] text-[16px] leading-[19px] text-[#363636]'>
                  Sosial medialarda bizi izləyin, yeniliklərdən ilk siz xəbərdar olun.
                </span>
                <div className='flex items-center gap-[20px]'>
                  
                  {/* <Link to="" > <FacebookSvg /> </Link> */}
                  <Link to="https://www.instagram.com/aikcompany/" target='__blank'  > <InstagramSvg /> </Link>
                  <Link to="https://youtube.com/@aikandcompany?si=DrlPa1KHj-h5u-lj" target='__blank'> <YoutubeSvg /> </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </section>

        {titles[0]? (
        <section className='flex flex-col gap-[20px] mt-[32px]'>
          <div>
            <h4 className='font-[450px] text-[22px] text-[#363636]'>Texniki Xüsusiyyətləri :</h4>
          </div>

          <div className='flex items-start gap-[42px]'>

            <div className='flex flex-col items-start gap-[8px]'>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[0]}</span>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[2]}</span>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[4]}</span>
            </div>

            <div className='flex flex-col items-start gap-[8px]'>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[1]}</span>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[3]}</span>
              <span className='font-[450px] text-[16px] leading-[20px] text-[#363636]'>{titles[5]}</span>
            </div>

          </div>
        </section>
        ) : null
        }

        <div className='mt-[52px]'>
          <GoodSold />
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
  productType: state.Data.productType,
  productSingleData: state.Data.productSingleData,
  lang_id: state.Data.lang_id,
})

const mapDispatchToProps = { getSingleProducts, getSingleProductType }
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleProduct))